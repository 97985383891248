.boasVindas {
    text-align: center;
}

.devName {
    font-family: 'karmic'; 
    color: black;
    font-size: 1.5rem;
}

.introducao {
    max-width: 63rem;
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 6%;
    
}

.dialogoCard {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    
    
}

.dialogoCard p {
    font-family: 'VT323', monospace;
    font-size: 24px;
    line-height: 26px;
}

.pic img {
    width: 70%;
    margin-top: 45%;
}

.pic {
    text-align: center;
}

@media (max-width: 750px) {

    .dialogoCard p {
        font-family: 'VT323', monospace;
        font-size: 20px;
        line-height: 22px;
    }

    .dialogoCard {
        display: flex;
        flex-direction: column-reverse;
        max-width: 90%;
        margin-left: 3%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .introducao {
        width: 90%;
    }

}