@font-face {
  font-family: 'karmic';
  src: url('./fonts/ka1.ttf') /*Para os demais navegadores*/;
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  margin: 0;
  padding: 0;
}

body #root {
  background-image: url("./wallpapers/layered-peaks-haikei2-16.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}

.nes-progress {
  width: 100%;
  height: 1.5rem;
  margin: 1rem auto 1.5rem auto;
}


h1 {
  font-family: 'karmic'; 
  color: black;
  font-size: 1.8rem;
}

h3, h4 {
  font-family: 'Press Start 2P', cursive;
  color: black;
  font-family: 'VT323', monospace;
  font-size: 25px;
  line-height: 25px;
}

.texto-inicio {
  color: black;
  flex-grow: 1;
}


p {
  font-size: 80%;
}

@media (max-width: 870px) {
  .nes-progress {
    width: 14rem;
    min-width: 20%;
    height: 1.5rem;
  }
}



@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .nes-container.is-rounded, .nes-progress.is-rounded {
      border-image-repeat: stretch;
  }

}

@media (max-width: 760px) {
  .nes-progress {
    width: 100%;
  }
}