.descricao {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 150%;
    width: 67%;
    max-width: 63rem; 
}

.texto-criado {
    text-align: center;
    width: 90%;
    margin-left: 1rem;
}

video {
    max-width: 100%;
    display: block;
    margin-bottom: 20px;
}

.texto-esquerda {
    text-align: left;
    width: 100%;
    margin-left: 55%;
    margin-inline-start: 0;
}

.descricao h1 {
    font-size: 1.5rem;
}

.descricao p, .descricao label {
    font-family: 'VT323', monospace;
    font-size: 24px;
    line-height: 26px;
}



.scaleInVerCenter {
	-webkit-animation: scale-in-ver-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-ver-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
}

@keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
}


.textoCriado {
    text-align: center;
    width: 90%;
    
}

.textoEsquerda {
    text-align: left;
    width: 100%;
    margin-left: 55%;
    margin-inline-start: 0;
}

@media (max-width: 760px) {
    .descricao {
        width: 80%;
        padding: 0.5rem;
    }

    .descricao p, .descricao label {
        font-family: 'VT323', monospace;
        font-size: 20px;
        line-height: 22px;
    }
}

.badges {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    
    
}



.mySpan {
    width: 85% !important;
    font-size: .7rem;
    margin-left: -4rem;
}

.gapClass {
    margin: 1rem auto;
    margin-bottom: 10px;
}


.reactColor {
    color: #61dafb !important;
}

