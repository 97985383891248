.langWrapper {
  display: flex;
  position: absolute;
  align-items: center;
  top: 10px;
  right: 10px;
  gap: 7px;
}

.langWrapper img {
  max-width: 35px;
  filter: grayscale(1);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
  transition: .3s;
}

@media (max-width: 425px) {
  .langWrapper {
    right: 0;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .langWrapper span {
    display: none;
  }

}

.langWrapper img.active, .langWrapper img:hover {
  filter: grayscale(0);
}

.langWrapper span {
  font-family: 'VT323', monospace;
  font-size: 25px;
}