@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
.Introduction_boasVindas__1YeFI {
    text-align: center;
}

.Introduction_devName__1U4UL {
    font-family: 'karmic'; 
    color: black;
    font-size: 1.5rem;
}

.Introduction_introducao__29yOn {
    max-width: 63rem;
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 6%;
    
}

.Introduction_dialogoCard__324qp {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    
    
}

.Introduction_dialogoCard__324qp p {
    font-family: 'VT323', monospace;
    font-size: 24px;
    line-height: 26px;
}

.Introduction_pic__3wLmF img {
    width: 70%;
    margin-top: 45%;
}

.Introduction_pic__3wLmF {
    text-align: center;
}

@media (max-width: 750px) {

    .Introduction_dialogoCard__324qp p {
        font-family: 'VT323', monospace;
        font-size: 20px;
        line-height: 22px;
    }

    .Introduction_dialogoCard__324qp {
        display: flex;
        flex-direction: column-reverse;
        max-width: 90%;
        margin-left: 3%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    .Introduction_introducao__29yOn {
        width: 90%;
    }

}
.SelIcons_selecao__Ud9el {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    margin: 2% 0;
}

.SelIcons_iconeSite__PJfsG {
    width: 40%;
    display: flex;
    justify-content: center
}

.SelIcons_iconeSite__PJfsG img {
    width: 100%;
    /* width: 40%; */
    margin-top: 5%;
    max-height: 80px;
    max-width: 80px;
    object-fit: contain;
}

.SelIcons_iconeImg__3xG2r, .SelIcons_iconeVisited__3GuVU {
    border-color: darkgoldenrod;
    border-radius: 1rem;
    padding: 0.3rem;
    border-style: solid;
    border-width: 4px;
    max-height: 115px;
    min-height: 115px;
    min-width: 115px;
    background: linear-gradient(#f89820, #f8e880, #f89820);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
}

.SelIcons_iconeImg__3xG2r:hover {  
    animation: SelIcons_rotatehover__5iYJm 2.5s linear infinite;
    
}

.SelIcons_iconeVisited__3GuVU {
    border-color: red;
    background: linear-gradient(#e23500, #f89820, #e23500);
    cursor: inherit;
}

@keyframes SelIcons_rotatehover__5iYJm {
    100% {
        transform: rotateY(360deg);
    }
}




@media (max-width: 870px) {
    .SelIcons_selecao__Ud9el {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;        
        width: 290px;
        
    }
}
.SelScreen_selScreen__3fos3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin-top: 3%;
    flex-wrap: wrap;
    max-width: 63rem;
}



.Description_descricao__3lLuE {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 150%;
    width: 67%;
    max-width: 63rem; 
}

.Description_texto-criado__30m2P {
    text-align: center;
    width: 90%;
    margin-left: 1rem;
}

video {
    max-width: 100%;
    display: block;
    margin-bottom: 20px;
}

.Description_texto-esquerda__3M-e0 {
    text-align: left;
    width: 100%;
    margin-left: 55%;
    margin-inline-start: 0;
}

.Description_descricao__3lLuE h1 {
    font-size: 1.5rem;
}

.Description_descricao__3lLuE p, .Description_descricao__3lLuE label {
    font-family: 'VT323', monospace;
    font-size: 24px;
    line-height: 26px;
}



.Description_scaleInVerCenter__3SDQA {
	animation: Description_scale-in-ver-center__3bSGF 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes Description_scale-in-ver-center__3bSGF {
    0% {
      transform: scaleY(0);
      opacity: 1;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
}


.Description_textoCriado__HPSMC {
    text-align: center;
    width: 90%;
    
}

.Description_textoEsquerda__3GfPV {
    text-align: left;
    width: 100%;
    margin-left: 55%;
    margin-inline-start: 0;
}

@media (max-width: 760px) {
    .Description_descricao__3lLuE {
        width: 80%;
        padding: 0.5rem;
    }

    .Description_descricao__3lLuE p, .Description_descricao__3lLuE label {
        font-family: 'VT323', monospace;
        font-size: 20px;
        line-height: 22px;
    }
}

.Description_badges__1lHiW {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    
    
}



.Description_mySpan__1I5jX {
    width: 85% !important;
    font-size: .7rem;
    margin-left: -4rem;
}

.Description_gapClass__3oRsw {
    margin: 1rem auto;
    margin-bottom: 10px;
}


.Description_reactColor__31Fjr {
    color: #61dafb !important;
}


.Contact_contato__1hcQ0 {
    margin-top: 3%;
    width: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.Contact_iconesContato__3gToj img {
    width: 12%;
    text-align: center;
    max-width: 60px;
}

.Contact_iconeContato__3ptFo {
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
    width: 30rem;
    max-width: 90vw;
    flex-wrap: wrap;
}

.Contact_iconeContato__3ptFo img {
    max-width: 60px;
}

.Contact_contato__1hcQ0 h3 {
    text-align: center;
    font-weight: 400;
}

@media (max-width: 870px) {
    .Contact_contato__1hcQ0 h3 {
      width: 14rem;
    }
  }
.LangSel_langWrapper__1SSwK {
  display: flex;
  position: absolute;
  align-items: center;
  top: 10px;
  right: 10px;
  grid-gap: 7px;
  gap: 7px;
}

.LangSel_langWrapper__1SSwK img {
  max-width: 35px;
  filter: grayscale(1);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
  transition: .3s;
}

@media (max-width: 425px) {
  .LangSel_langWrapper__1SSwK {
    right: 0;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .LangSel_langWrapper__1SSwK span {
    display: none;
  }

}

.LangSel_langWrapper__1SSwK img.LangSel_active__2S6XA, .LangSel_langWrapper__1SSwK img:hover {
  filter: grayscale(0);
}

.LangSel_langWrapper__1SSwK span {
  font-family: 'VT323', monospace;
  font-size: 25px;
}
@font-face {
  font-family: 'karmic';
  src: url(/static/media/ka1.5df8cd54.ttf) ;
  font-weight: normal;
  font-style: normal;
}

:root {
  margin: 0;
  padding: 0;
}

body #root {
  background-image: url(/static/media/layered-peaks-haikei2-16.74b2b2ab.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}

.nes-progress {
  width: 100%;
  height: 1.5rem;
  margin: 1rem auto 1.5rem auto;
}


h1 {
  font-family: 'karmic'; 
  color: black;
  font-size: 1.8rem;
}

h3, h4 {
  font-family: 'Press Start 2P', cursive;
  color: black;
  font-family: 'VT323', monospace;
  font-size: 25px;
  line-height: 25px;
}

.texto-inicio {
  color: black;
  flex-grow: 1;
}


p {
  font-size: 80%;
}

@media (max-width: 870px) {
  .nes-progress {
    width: 14rem;
    min-width: 20%;
    height: 1.5rem;
  }
}



@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .nes-container.is-rounded, .nes-progress.is-rounded {
      border-image-repeat: stretch;
  }

}

@media (max-width: 760px) {
  .nes-progress {
    width: 100%;
  }
}
