.selecao {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    margin: 2% 0;
}

.iconeSite {
    width: 40%;
    display: flex;
    justify-content: center
}

.iconeSite img {
    width: 100%;
    /* width: 40%; */
    margin-top: 5%;
    max-height: 80px;
    max-width: 80px;
    object-fit: contain;
}

.iconeImg, .iconeVisited {
    border-color: darkgoldenrod;
    border-radius: 1rem;
    padding: 0.3rem;
    border-style: solid;
    border-width: 4px;
    max-height: 115px;
    min-height: 115px;
    min-width: 115px;
    background: linear-gradient(#f89820, #f8e880, #f89820);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
}

.iconeImg:hover {  
    animation: rotatehover 2.5s linear infinite;
    
}

.iconeVisited {
    border-color: red;
    background: linear-gradient(#e23500, #f89820, #e23500);
    cursor: inherit;
}

@keyframes rotatehover {
    100% {
        transform: rotateY(360deg);
    }
}




@media (max-width: 870px) {
    .selecao {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;        
        width: 290px;
        
    }
}