.contato {
    margin-top: 3%;
    width: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.iconesContato img {
    width: 12%;
    text-align: center;
    max-width: 60px;
}

.iconeContato {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 30rem;
    max-width: 90vw;
    flex-wrap: wrap;
}

.iconeContato img {
    max-width: 60px;
}

.contato h3 {
    text-align: center;
    font-weight: 400;
}

@media (max-width: 870px) {
    .contato h3 {
      width: 14rem;
    }
  }