.selScreen {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin-top: 3%;
    flex-wrap: wrap;
    max-width: 63rem;
}


